<template>
  <div class="Tests">
    <div class="ProjectList noselect">
      <span class="Title">
        Список проектов
      </span>

      <div
        v-for="proj in userProjectsList"
        :key="proj"
        :class="{
          active: selectedProjectForEdit == proj.id,
        }"
      >
        <span>{{ `${proj.name}` }}</span>
        <span :style="`color: ${getJobLatestStatus(proj).show_color};`">{{
          `Статус: ${getJobLatestStatus(proj).name}`
        }}</span>

        <span
          v-if="getJobLatestStatus(proj).name == 'Отклонено'"
          :style="`color: ${getJobLatestStatus(proj).show_color};`"
          >{{ `Причина отказа: ${getJobLatestStatus(proj).comment}` }}</span
        >

        <span
          :style="
            `color: ${
              proj.archieve ? 'rgb(235, 87, 87)' : 'rgb(111, 207, 151)'
            };`
          "
          >{{ `Архив: ${proj.archieve ? "Да" : "Нет"}` }}</span
        >

        <span
          >Организация: "{{
            proj?.user?.parent_company?.length != null &&
            proj?.user?.parent_company?.length != 0
              ? proj?.user?.parent_company[0]?.name
              : `{Проблема с бд?}`
          }}"</span
        >

        <span
          >Тип организации: "{{
            proj?.user?.parent_company?.length != null &&
            proj?.user?.parent_company?.length != 0
              ? proj?.user?.parent_company[0]?.type
              : `{Проблема с бд?}`
          }}"</span
        >

        <span
          >ФИО Владельца: {{ proj?.user?.surname }} {{ proj?.user?.name }}
          {{ proj?.user?.patronymic }}</span
        >
        <span>Email Владельца: {{ proj?.user?.email }}</span>
        <span>Телефон Владельца: {{ proj?.user?.tel }}</span>

        <span>Кол-во задач: {{ getJobsCounter(proj.id) }}</span>
        <span v-if="getTextJobsStatuses(proj.id) != null"
          >Статусы задач: <br />{{ getTextJobsStatuses(proj.id) }}</span
        >

        <span
          >Добавлен: {{ getNormalDate(proj?.createdAt) }}
          {{
            getNormalDate(proj?.createdAt) === getNormalDate(proj?.updatedAt)
              ? ""
              : `/ Обновлен: ${getNormalDate(proj?.updatedAt)}`
          }}</span
        >

        <div
          class="ql-snow"
          style="position: relative; display: block; width: 100%; height: fit-content; border: 1px solid white;"
        >
          <span
            style="position: relative; display: block; width: 100%; height: fit-content; text-align: left;"
            >Описание:
          </span>
          <span
            class="ql-editor"
            style="position: relative; display: block; width: 100%; height: fit-content;"
            v-html="proj.description"
          >
          </span>
        </div>

        <GradientButton
          @click.native="
            status = getJobLatestStatus(proj).name;
            comment = getJobLatestStatus(proj).comment;
            jobname = proj.name;
            jobdesc = proj.description;
            jobarchieve = proj.archieve;
            selectedProjectForEdit == null
              ? (selectedProjectForEdit = proj.id)
              : selectedProjectForEdit == proj.id
              ? (selectedProjectForEdit = null)
              : (selectedProjectForEdit = proj.id);

            selectedJobForEdit = null;
          "
          :ButtonText="
            selectedProjectForEdit == proj.id ? 'Выбрано' : 'Выбрать'
          "
        />
        <GradientButton
          v-if="selectedProjectForEdit == proj.id"
          @click.native="
            modalMode = 'project';
            jobEditOpened = true;
          "
          ButtonText="Изменить статус"
        />
        <GradientButton
          v-if="selectedProjectForEdit == proj.id"
          @click.native="
            StatusArchive = proj.Project_Job_Statuses;
            showStatusHistory = true;
          "
          ButtonText="История модерации"
        />
      </div>
    </div>

    <div class="JobsList">
      <div class="Head">
        <span class="Title">
          Список задач у проекта
        </span>
      </div>
      <template v-if="selectedProjectForEdit != null">
        <div class="Body">
          <div
            class="JobContainer"
            v-for="job in jobsList"
            v-show="job.nProjectId == selectedProjectForEdit"
            :key="job"
            :class="{
              active: selectedJobForEdit == job.id,
              job: true,
              noselect: true,
            }"
          >
            <span class="title"> {{ job.name }} </span>
            <span :style="`color: ${getJobLatestStatus(job).show_color};`">{{
              `Статус: ${getJobLatestStatus(job).name}`
            }}</span>
            <span
              v-if="getJobLatestStatus(job).name == 'Отклонено'"
              :style="`color: ${getJobLatestStatus(job).show_color};`"
              >{{ `Причина отказа: ${getJobLatestStatus(job).comment}` }}</span
            >
            <span
              :style="
                `color: ${
                  job.archieve ? 'rgb(235, 87, 87)' : 'rgb(111, 207, 151)'
                };`
              "
              >{{ `Архив: ${job.archieve ? "Да" : "Нет"}` }}</span
            >
            <span
              :style="
                `color: ${
                  job.jobHire ? 'rgb(182, 87, 235)' : 'rgb(111, 207, 151)'
                };`
              "
              >{{ `Трудоустройство: ${job.jobHire ? "Да" : "Нет"}` }}</span
            >
            <span
              :style="
                `color: ${
                  job.price != null ? 'rgb(182, 87, 235)' : 'rgb(111, 207, 151)'
                };`
              "
              >{{ `Задача платная: ${job.price != null ? "Да" : "Нет"}` }}</span
            >

            <span>Уровень сложности: {{ job.level }}</span>
            <span>Направление: {{ directionsList(job.sections) }}</span>
            <div
              class="ql-snow"
              style="position: relative; display: block; width: 100%; height: fit-content; border: 1px solid white;"
            >
              <span
                style="position: relative; display: block; width: 100%; height: fit-content; text-align: left;"
                >Описание:
              </span>
              <span
                class="ql-editor"
                style="position: relative; display: block; width: 100%; height: fit-content;"
                v-html="job.description"
              >
              </span>
            </div>
            <GradientButton
              @click.native="
                status = getJobLatestStatus(job).name;
                comment = getJobLatestStatus(job).comment;
                setDifficult(job.id);
                jobname = job.name;
                jobdesc = job.description;
                jobarchieve = job.archieve;
                jobhire = job.jobHire;
                jobpriceBoolean = job.price != 0 && job.price != null;
                jobpriceNumber = job.price;
                jobsectionisnull = directionsListRaw(job.sections).length === 0;

                selectedJobForEdit == null
                  ? (selectedJobForEdit = job.id)
                  : selectedJobForEdit == job.id
                  ? (selectedJobForEdit = null)
                  : (selectedJobForEdit = job.id);
              "
              :ButtonText="selectedJobForEdit == job.id ? 'Выбрано' : 'Выбрать'"
            />
            <GradientButton
              v-if="selectedJobForEdit == job.id"
              ButtonText="Изменить статус"
              @click.native="
                modalMode = 'job';
                jobEditOpened = true;
              "
            />
            <GradientButton
              v-if="selectedJobForEdit == job.id"
              @click.native="
                StatusArchive = job.Project_Job_Statuses;
                showStatusHistory = true;
              "
              ButtonText="История модерации"
            />
          </div>
        </div>
      </template>
    </div>

    <el-dialog
      v-model="jobEditOpened"
      :title="
        modalMode == 'project'
          ? 'Изменение статуса ПРОЕКТА'
          : 'Изменение статуса ЗАДАЧИ'
      "
      width="40%"
    >
      <div class="EditModal">
        <h3>Сведения об {{ modalMode == "project" ? "проекте" : "задаче" }}</h3>

        <div
          style="position: relative; display: flex; flex-direction: column; gap: 10px; width: fit-content;"
        >
          <div class="checkbox_agreement">
            <input
              type="checkbox"
              id="advert_checkbox"
              name="advert_checkbox"
              v-model="jobarchieve"
              style="width: 24.4px !important;"
            />
            <label for="advert_checkbox">Архив</label>
          </div>

          <template v-if="modalMode != 'project'">
            <div class="checkbox_agreement">
              <input
                type="checkbox"
                id="advert_checkbox"
                name="advert_checkbox"
                v-model="jobhire"
                style="width: 24.4px !important;"
              />
              <label for="advert_checkbox">Есть трудоустройство</label>
            </div>

            <div
              style="position: relative; display: flex; flex-direction: column; gap: 5px; width: 250px;"
            >
              <div class="checkbox_agreement">
                <input
                  type="checkbox"
                  id="advert_checkbox"
                  name="advert_checkbox"
                  v-model="jobpriceBoolean"
                  style="width: 24.4px !important;"
                />
                <label for="advert_checkbox">Задача платная</label>
              </div>

              <input
                type="text"
                placeholder="если == 0, задача не платная"
                v-model="jobpriceNumber"
                v-if="jobpriceBoolean"
              />
            </div>
          </template>
        </div>

        <input type="text" v-model="jobname" placeholder="Название задачи" />

        <div
          class="QEditor QEditorFixHeight"
          style="position: relative; width: auto; height: 400px;"
        >
          <QuillEditor
            ref="QuillEditorAJD"
            toolbar="full"
            theme="snow"
            contentType="html"
            :content="jobdesc"
            @textChange="
              handleEditorEditChanges($event, 'AJD', 'QuillEditorAJD')
            "
            @ready="handleEditorEditReady('AJD', 'QuillEditorAJD')"
          />
        </div>
        <!-- <textarea type="text" v-model="jobdesc" placeholder="Описание задачи" /> -->

        <span
          v-if="jobsectionisnull"
          style="font-size: 18px; color: red !important;"
          >ВАМ НУЖНО ВЫБРАТЬ УРОВЕНЬ СЛОЖНОСТИ И НАПРАВЛЕНИЕ</span
        >
        <el-select
          v-if="modalMode != 'project'"
          v-model="DifficultySelected"
          class="m-2"
          placeholder="Select"
          size="large"
        >
          <el-option
            v-for="item in DifficultyLevels"
            :key="item.difficult"
            :label="item.name"
            :value="item.difficult"
          />
        </el-select>

        <el-select
          v-if="modalMode != 'project'"
          v-model="SectionSelected"
          class="m-2"
          placeholder="Select"
          size="large"
        >
          <el-option
            v-for="item in SectionsList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>

        <h3>Смена статуса</h3>

        <el-select
          v-model="status"
          class="m-2"
          placeholder="Select"
          size="large"
        >
          <el-option label="Ожидает" value="Ожидает" />
          <el-option label="Отклонено" value="Отклонено" />
          <el-option label="Одобрено" value="Одобрено" />
        </el-select>

        <textarea
          type="text"
          v-if="status == 'Отклонено'"
          v-model="comment"
          placeholder="Причина отказа"
        />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            @click="
              jobEditOpened = false;
              jobsectionisnull = false;
            "
            >Отмена</el-button
          >
          <el-button
            type="primary"
            @click="
              changeStatus();
              jobEditOpened = false;
            "
          >
            Сохранить
          </el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="showStatusHistory" title="История статусов" width="40%">
      <div class="EditModal">
        <el-timeline v-if="StatusArchive != null || StatusArchive.length != 0">
          <el-timeline-item
            v-for="(status, indx) of StatusArchive"
            :key="`${indx}${status.id}`"
            center
            placement="bottom"
            type="success"
            hide-timestamp="true"
          >
            <el-card>
              <h5 :style="`color: ${getStatusColor(status)};`">
                {{ status.status }}
              </h5>
              <h6
                :style="`color: ${getStatusColor(status)};`"
                v-if="status.status == 'Отклонено'"
              >
                Комментарий: {{ status.comment }}
              </h6>
              <h7>Дата: {{ getNormalDate(status.createdAt) }}</h7>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="showStatusHistory = false">Закрыть</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getSections } from "@/api/competence.js";

import { findById } from "@/api/user.js";
import { mapGetters, mapMutations } from "vuex";
import { refresh } from "@/api/auth.js";

import { findAll, update } from "@/api/n_project.js";
import {
  getAll,
  createJob,
  findAllJobByUser,
  removeJob,
  getAllDifficulties,
  updateJob,
} from "@/api/job.js";

import { createStatus } from "@/api/project_job_status.js";

import { h } from "vue";
import { ElNotification } from "element-plus";

import GradientButton from "@/components/CustomElements/ButtonGradient.vue";

import { QuillEditor } from "@vueup/vue-quill";

export default {
  components: { GradientButton, QuillEditor },
  data() {
    return {
      jobsList: [],
      userProjectsList: [],

      selectedProjectForEdit: null,
      selectedJobForEdit: null,
      jobEditOpened: false,

      jobname: "",
      jobdesc: "",
      jobarchieve: "",
      jobhire: "",
      jobpriceBoolean: false,
      jobpriceNumber: null,
      jobsectionisnull: false,

      modalMode: "",

      status: "Ожидает",
      comment: null,

      DifficultySelected: 3,
      DifficultyLevels: [],

      SectionSelected: "",
      SectionsList: [],

      StatusArchive: [],

      showStatusHistory: false,
    };
  },
  async created() {
    // if (this.user == null) {
    //   this.updateUser();
    // } else {
    //   this.getUser(this.user.id);
    // }

    await this.getActualJobList();

    //заполнение блока компетенций направлениями, уровнями пользователя и необходимыми уровнями для проекта
    getSections().then((response) => {
      let sections = response.data;
      let competence = [];

      let self = this;

      let tags = new Set(sections.map((section) => section.tag));
      tags.forEach((tag) => {
        let filteredSections = sections
          .filter((section) => section.tag === tag)
          .map((section) => {
            return {
              name: section.name,
              id: section.id,
              levels: section.degrees
                .flatMap((degree) => degree.levels.reverse())
                .reverse()
                .map((x) => x.id),
            };
          });
        competence.push({
          name: tag,
          counter: filteredSections.length,
          CBlock: filteredSections,
        });
      });

      if (competence.length >= 1) {
        self.SectionsList = competence[0].CBlock;

        if (competence[0].CBlock.length > 0) {
          self.SectionSelected = competence[0].CBlock[0].id;
        }
      }
    });

    getAllDifficulties().then((response) => {
      this.DifficultyLevels = response.data;
      this.DifficultySelected =
        response.data != null && response.data.length != 0
          ? response.data[0]["difficult"]
          : 0;
    });
  },
  watch: {
    user: {
      handler(val) {
        if (this.id) {
          this.id = this.user.id;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),
  },
  methods: {
    directionsList(sects) {
      return sects.map((x) => x.name).join(", ");
    },
    directionsListRaw(sects) {
      if (sects == null) {
        return [];
      }
      return sects.map((x) => x.id);
    },
    ...mapMutations("auth", ["SET_USER"]),
    async updateUser() {
      const response = await refresh();
      const userResponse = response.user;
      this.getUser(userResponse.id);
    },
    getUser(id) {
      if (id != undefined) {
        findById(id).then((response) => {
          this.SET_USER(response.data);
        });
      }
    },

    async createEmptyJob() {
      let self = this;

      let JobsArray = [];
      let LevelsArray = [];
      let startJobData = {
        name: this.jobname,
        description: this.jobdesc,
        projectId: this.selectedProjectForEdit,
        level_val: this.DifficultySelected,
        // authorId: this.user.id,
        authorId: null,
        JobSectionId: this.SectionSelected,
        order: this.jobsList.length + 1,
        sectionId: this.SectionSelected,
      };

      let findDifficultIndex = this.DifficultyLevels.findIndex(
        (obj) => obj.difficult === this.DifficultySelected
      );
      startJobData.level = this.DifficultyLevels[findDifficultIndex]["name"];

      let findSectionIDFromList = this.SectionsList.findIndex(
        (obj) => obj.id === this.SectionSelected
      );
      LevelsArray = this.SectionsList[findSectionIDFromList].levels;
      LevelsArray = LevelsArray.filter((num, i) => {
        if (i < self.DifficultySelected) {
          return num;
        }
      });
      startJobData.levels_array = LevelsArray;

      JobsArray.push(startJobData);
      await createJob(JobsArray);
      this.getActualJobList();
    },

    async removeJob() {
      let JobsArray = [];
      JobsArray.push(this.selectedJobForEdit);

      await removeJob(JobsArray);
      this.selectedJobForEdit = null;

      this.getActualJobList();
    },

    async getActualJobList() {
      let projectListRaw = await findAll();
      this.userProjectsList = projectListRaw.data;

      let temp = await getAll();
      this.jobsList = temp.data;
    },

    getDirectionByID(sec_id) {
      let Section = this.SectionsList.find((sec) => {
        return String(sec_id) === String(sec.id);
      });

      if (Section != null) {
        return Section.name;
      } else {
        return "";
      }
    },

    getJobLatestStatus(task) {
      let status_colors = {
        red: "#EB5757",
        blue: "#12AEE3",
        green: "#6FCF97",
      };

      let TaskLastStatus =
        task?.Project_Job_Statuses != null &&
        task?.Project_Job_Statuses?.length == 0
          ? "Ожидает"
          : task?.Project_Job_Statuses[task?.Project_Job_Statuses?.length - 1]
              ?.status;

      let clear_statuses = [
        {
          name: "Ожидает",
          show_name: "Ожидает модерации",
          show_color: status_colors.blue,
        },
        {
          name: "Одобрено",
          show_name: "Задача опубликована",
          show_color: status_colors.green,
        },
        {
          name: "Отклонено",
          show_name: "Есть замечания",
          show_color: status_colors.red,
          comment:
            task?.Project_Job_Statuses[task?.Project_Job_Statuses?.length - 1]
              ?.comment,
        },
      ];

      switch (TaskLastStatus) {
        case "Ожидает": {
          let obj = clear_statuses.find((status) => {
            if (status.name == TaskLastStatus) {
              return true;
            }
          });

          return obj;
        }
        case "Отклонено": {
          let obj = clear_statuses.find((status) => {
            if (status.name == TaskLastStatus) {
              return true;
            }
          });

          return obj;
        }
        case "Одобрено": {
          let obj = clear_statuses.find((status) => {
            if (status.name == TaskLastStatus) {
              return true;
            }
          });

          return obj;
        }
      }
    },

    setDifficult(id) {
      let JobForEdit = this.jobsList.find((obj) => {
        return obj.id === id;
      });

      if (id == null) {
        alert("Возникла какая-то ошибка!");
        return 0;
      }

      this.SectionSelected = this.directionsListRaw(JobForEdit.sections)[0];

      let findDifficultID = this.DifficultyLevels.find(
        (obj) => obj.name === JobForEdit.level
      );

      if (findDifficultID == null) {
        alert("Возникла какая-то ошибка!");
        return 0;
      }

      this.DifficultySelected = findDifficultID.difficult;
    },
    async changeStatus() {
      let temp = {
        status: this.status,
        comment: this.comment,
      };

      if (this.modalMode == "project") {
        temp.nProjectId = this.selectedProjectForEdit;
      }
      if (this.modalMode == "job") {
        temp.JobId = this.selectedJobForEdit;

        /*
        Изначально, возможность редактировать была доступна,
        только если организация не выставила самостоятельно данные поля.

        В ходе работ, выяснилось, что блокировку нужно снять.
        Чтобы не вспоминать потом, что нужно было оборачивать в if конструкцию,
        пришлось написать такое странное условие, которое в любом случае даст true.

        this.jobsectionisnull - даёт разблокировку, ЕСЛИ поля не были заполненны.
      */
        if (this.jobsectionisnull || !this.jobsectionisnull) {
          let startJobData = {
            level_val: this.DifficultySelected,
            JobSectionId: this.SectionSelected,
            sections: [this.SectionSelected],
            name: this.jobname,
            description: this.jobdesc,
            archieve: this.jobarchieve,
            jobHire: this.jobhire,
            price: this.jobpriceBoolean
              ? this.jobpriceNumber == 0
                ? null
                : this.jobpriceNumber
              : null,
          };

          let findDifficultIndex = this.DifficultyLevels.findIndex(
            (obj) => obj.difficult === this.DifficultySelected
          );
          startJobData.level = this.DifficultyLevels[findDifficultIndex][
            "name"
          ];

          let findSectionIDFromList = this.SectionsList.findIndex(
            (obj) => obj.id === this.SectionSelected
          );
          let LevelsArray = this.SectionsList[findSectionIDFromList].levels;
          LevelsArray = LevelsArray.filter((num, i) => {
            if (i < this.DifficultySelected) {
              return num;
            }
          });
          startJobData.levels = LevelsArray;

          await updateJob(this.selectedJobForEdit, startJobData);
        }
      }

      await createStatus(temp);

      if (this.modalMode == "project") {
        await update(this.selectedProjectForEdit, {
          name: this.jobname,
          description: this.jobdesc,
          archieve: this.jobarchieve,
        });
      }

      await this.getActualJobList();
      this.jobEditOpened = false;
    },

    getStatusColor(status) {
      let status_colors = {
        red: "#EB5757",
        blue: "rgb(0, 112, 255)",
        green: "rgb(0, 179, 28)",
      };

      switch (status.status) {
        case "Ожидает": {
          return status_colors.blue;
        }
        case "Отклонено": {
          return status_colors.red;
        }
        case "Одобрено": {
          return status_colors.green;
        }
      }
    },

    getNormalDate(timestamp) {
      return `${timestamp
        .split("T")[0]
        .split("-")
        .reverse()
        .join(".")} ${timestamp.split("T")[1].split(".")[0]}`;
    },

    getTextJobsStatuses(projid) {
      let projJobs = this.jobsList.filter((job) => job.nProjectId === projid);

      const statusCounts = projJobs.reduce((acc, obj) => {
        acc[
          obj.Project_Job_Statuses[obj.Project_Job_Statuses.length - 1].status
        ] =
          (acc[
            obj.Project_Job_Statuses[obj.Project_Job_Statuses.length - 1].status
          ] || 0) + 1;
        return acc;
      }, {});

      const result = Object.entries(statusCounts).map(([status, count]) => ({
        status,
        count,
      }));

      if (result.length === 0) {
        return null;
      } else {
        const _text_result = result
          .map((obj) => `${obj.status} - ${obj.count}`)
          .join(", ");
        return _text_result;
      }
    },
    getJobsCounter(projid) {
      return this.jobsList.filter((job) => job.nProjectId === projid).length;
    },

    handleEditorEditChanges(delta, setTo, fromEditorName) {
      let htmlDataFromEditor = this.$refs[fromEditorName].getHTML();
      switch (setTo) {
        case "AJD": {
          this.jobdesc = htmlDataFromEditor;
          break;
        }
      }
    },
    handleEditorEditReady(setTo, fromEditorName) {
      let dataToSet = "";
      switch (setTo) {
        case "AJD": {
          dataToSet = this.jobdesc;
          break;
        }
      }
      this.$refs[fromEditorName].setHTML(dataToSet);
    },
  },
};
</script>

<style scoped>
@import "./../../../node_modules/@vueup/vue-quill/dist/vue-quill.core.prod.css";
@import "./../../../node_modules/@vueup/vue-quill/dist/vue-quill.snow.prod.css";

.EditModal {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;
}

.Actions {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}
.JobContainer {
  position: relative;

  cursor: pointer;

  padding: 30px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;

  background-color: #19173b;
  box-shadow: 0px 0px 5px #6f6f6f;
  border-radius: 15px;
}
.JobContainer > .title {
  font-size: 22px;
}
.JobContainer > * {
  text-align: start;
}

.Tests {
  position: relative;

  padding-top: 60px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 30px;

  padding: 50px 8vw;

  width: 100%;
  min-height: 100vh;

  color: white;
}

.ProjectList {
  position: relative;

  width: 50%;

  padding: 12px;
  margin: 0% auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  height: 90vh;
  overflow-y: auto;

  flex-shrink: 1;
}

.ProjectList > div {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  padding: 24px;

  border-radius: 15px;

  cursor: pointer;

  background-color: #19173b;
  box-shadow: 0px 0px 5px #6f6f6f;
}
.ProjectList > div > span {
  text-align: left;
}
.ProjectList > div > span:nth-child(1) {
  font-size: 22px;
}

.ProjectList > div.active {
  position: relative;

  color: white;

  background: rgb(43, 53, 88);
  background: linear-gradient(
    128deg,
    rgba(43, 53, 88, 1) 0%,
    rgba(164, 79, 139, 1) 54%,
    rgba(168, 188, 255, 1) 100%
  );
}
.active {
  position: relative;

  color: white;

  background: rgb(43, 53, 88);
  background: linear-gradient(
    128deg,
    rgba(43, 53, 88, 1) 0%,
    rgba(164, 79, 139, 1) 54%,
    rgba(168, 188, 255, 1) 100%
  );
}

.JobsList {
  width: 50%;
  height: 90vh;
  overflow-y: auto;

  flex-shrink: 1;
}

.JobsList,
.JobsList > .Body {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: left;

  padding: 12px;

  height: 90vh;
  overflow-y: auto;

  gap: 20px;
}
.JobsList > .Head {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 40px;
}

.Title {
  position: relative;

  font-size: 24px;
  font-weight: 600;

  flex-shrink: 0;
}
</style>

<style scoped>
.checkbox_agreement {
  color: #fff;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-wrap: nowrap;
  margin-top: 0.3rem;
  align-items: center;
}

.checkbox_agreement a {
  font-weight: 500;
  color: #6c59f7;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: transparent;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.1em solid #6e6a9a;
  border-radius: 4px;
  padding: 0.7rem;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;

  margin-right: 1rem;
}

input[type="checkbox"]:checked {
  background-image: linear-gradient(221.26deg, #c548ab -3.4%, #635bff 101.08%);
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #fff;

  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}
</style>
